
.autocomplete-search {
    display: none;
    width: 485px;
    height: 300px;
    position: absolute;
    top: 67px;
    left: 32px;
    z-index: 9999;
    background: white;
    border: 1px solid #e0e0e0;
    overflow-y: auto;
}

.autocomplete-search > ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.autocomplete-search > ul li {
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
    float: left;
    width: 100%;
    clear: both;
}

.autocomplete-search > ul li a, .autocomplete-search > ul li a:hover {
    color: #333e48;
    background: #fff;
}

.autocomplete-search > ul li img {
    width: 40px;
    float: left;
    margin-right: 10px;
}

.autocomplete-search > ul li span {
    float: left;
    width: 350px;
}

@media (max-width:600px)  {
    .autocomplete-search {
        display: none;
        width: 100vw;
        height: 300px;
        position: absolute;
        top: 42px;
        left: -15px;
        z-index: 9999;
        background: white;
        border: 1px solid #e0e0e0;
        overflow-y: auto;
    }
}
